/**
 * @file impressions.jsx
 * @description Impressions component.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import { Client, Storage } from "appwrite";

/**
 * @function Impressions
 * @throws {Error} If API endpoint or project ID is not set or if the API call fails.
 * @returns {JSX.Element} Impressions component.
 */

function Impressions() {
  const client = new Client();
  const storage = new Storage(client);

  client
    .setEndpoint(process.env.REACT_APP_APPWRITE_ENDPOINT) // Your API Endpoint
    .setProject(process.env.REACT_APP_APPWRITE_PROJECT);

  const images = [
    {
      fileID: "screenshot_1",
      alt: "",
      text: "Offer your visitors and members a wonderful design that is easy to get used to. The mix of colours throughout the design creates a fascinating recognition value. With “Role Royale” you are on the right side. While the design is kept very dark, small – almost inconspicuous – lighter tones are hidden in the style. White folders or bright badge labels also bring a pleasant light into the darkness.",
    },
    {
      fileID: "screenshot_2",
      alt: "",
      text: "The size of the logo was fixed in a stable way via CSS. This makes it possible to include larger images in the design without unwanted side effects. The background can also be exchanged without any problems. “Role Royale” uses a fixed background.",
    },
    {
      fileID: "screenshot_3",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_4",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_5",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_6",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_7",
      alt: "",
      text: "",
    },
  ];

  return (
    <div className="section">
      <h2 className="title is-2 has-text-centered">Impressions</h2>
      <h3 className="subtitle is-4 has-text-centered">
        A few impressions of the “Role Royale”-Design.
      </h3>
      <br />
      <div className="columns is-multiline">
        {images.map((image) => (
          <div className="column is-half" key={image.fileID}>
            <div className="card">
              <div className="card-image">
                <a
                  href={
                    "https://api.felix-d1strict.de/v1/storage/buckets/63f1015c7b0325dbb525/files/" +
                    image.fileID +
                    "/view?project=63cd3e953da71c7c7b80"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <figure className="image is-4by3">
                    <img
                      src={storage.getFilePreview(
                        "63f1015c7b0325dbb525",
                        image.fileID,
                        800,
                        600,
                        "center",
                        70,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        "webp",
                      )}
                      alt={image.alt ? image.alt : "Screenshot"}
                      width="800"
                      height="600"
                      loading="lazy"
                    />
                  </figure>
                </a>
              </div>
              {image.text && (
                <div className="card-content">
                  <div className="content">{image.text}</div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Impressions;
