/**
 * @file changelog.jsx
 * @description Changelog page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import Hero from "../components/hero";

/**
 * @function ChangelogPage
 * @description Changelog page.
 * @returns {JSX.Element} Changelog page.
 */

function ChangelogPage() {
  return (
    <>
      <Meta
        title="Changelog"
        description="Get the latest news about Role Royale."
      />
      <Hero title="Changelog" subtitle="Get the latest news about Role Royale." />
      <div className="section">
        <div className="container">
          <div className="timeline is-centered">
            <header className="timeline-header">
              <span className="tag is-medium">
                Version 3.0.0
              </span>
            </header>
            <div className="timeline-item">
              <div className="timeline-marker"></div>
              <div className="timeline-content">
                <p className="heading">
                  <span className="tag">3.0.1</span>{" "}
                  <time dateTime="2018-11-24T00:03:53+01:00">
                    24th November 2018
                  </time>
                </p>
                <p>
                  <span className="has-text-warning">&bull;</span> The transparent background of the item ".content" has been removed for better readability.
                  <br />
                  <span className="has-text-warning">&bull;</span> The ".buttonGroupNavigation" (eg delete, report or edit whiteboard entries) has been made more recognizable.
                  <br />
                  <span className="has-text-warning">&bull;</span> Hovering tables and articles no longer causes a white overlay effect.
                  <br />
                  <span className="has-text-warning">&bull;</span> Narrow texts that serve as a category description have been made more recognizable.
                  <br />
                  <span className="has-text-warning">&bull;</span> Added integration support for the "Modern Shoutbox" plugin from Darkwood Studios, if used in the top boxes.
                  <br />
                  <b><span className="has-text-danger">Please delete the package "Role Royale" beforehand via the package management, but not via the styles section. Then install the update. It is possible that the paths to media files (e.g., header image, background image) must be re-entered into the design.</span></b>
                </p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-marker"></div>
              <div className="timeline-content">
                <p className="heading">
                  <span className="tag">3.0.1</span>{" "}
                  <time dateTime="2020-01-02T04:08:53+01:00">
                    2nd January 2020
                  </time>
                </p>
                <p>
                  <span className="has-text-success">&bull;</span> Added package-update-instructions.
                  <br />
                  <span className="has-text-warning">&bull;</span> Dead-Copyright-Link replaced.
                </p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-marker is-primary"></div>
              <div className="timeline-content">
                <p className="heading">
                  <span className="tag is-primary">5.5.0</span>{" "}
                  <time dateTime="2022-07-18T14:36:02+02:00">
                    18th July 2022
                  </time>
                </p>
                <p>
                  <span className="has-text-success">&bull;</span> Compatibility
                  for WoltLab Suite™ Core 5.5.0
                  <br />
                  <span className="has-text-warning">&bull;</span> Optimization
                  for the WoltLab Suite™ Core 5.5
                  <br />
                  <span className="has-text-danger">&bull;</span> The minimum
                  version for the WoltLab Suite™ Core has been raised to 5.5.0
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangelogPage;
